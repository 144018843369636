
import { PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../store'

@Component({
  name: 'FlowName'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly nameField!: HTMLElement

  @Prop() readonly flowId!: string
  @Prop() readonly permission!: PermissionType
  @Prop({ type: Boolean }) readonly autofocus?: boolean

  model = ''
  editing = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get flowName () {
    return this.flow.name
  }

  @Watch('flowName')
  onModelObjectNameChanged (newVal?: string) {
    this.model = newVal || ''
  }

  mounted () {
    this.model = this.flow.name || ''
  }

  blurField () {
    this.editing = false
    this.$emit('blur')
  }

  enterKey (event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault()
      this.nameField.blur()
    }
  }

  startEditing () {
    this.editing = true
  }

  updateName (name: string) {
    if (this.flow.name !== name && this.permission !== 'read') {
      const revertTasks: Task[] = [{
        id: this.flow.id,
        props: {
          name: this.flow.name
        },
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.flow.id, { name })
      this.flowModule.setFlowVersion(flow)
      this.editorModule.addToTaskQueue({
        func: () => this.flowModule.flowUpdate({
          flowId: flow.id,
          landscapeId: this.currentLandscape.id,
          props: flowUpdate,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: flow.id,
          props: flowUpdate,
          type: 'flow-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      this.$emit('change')
    }
  }
}
